import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import { Row, Col } from 'react-grid-system'
import { Formik, Form, Field } from 'formik'
import cn from 'classnames'
import axios from 'axios'
import * as Yup from 'yup'
import { Icon } from 'react-icons-kit'
import { cloud_upload } from 'react-icons-kit/ikons/cloud_upload'
import { clip } from 'react-icons-kit/ikons/clip'
import swal from '@sweetalert/with-react'
import ClipLoader from 'react-spinners/ClipLoader'

import Box from 'reusecore/src/elements/Box'
import Text from 'reusecore/src/elements/Text'
import Heading from 'reusecore/src/elements/Heading'
import Image from 'reusecore/src/elements/Image'
import Button from 'reusecore/src/elements/Button'
import Input from 'reusecore/src/elements/Input'
import Container from '../../../components/UI/Container'

import { ButtonWrapper } from '../../Portfolio/portfolio.style'
import { ActiveStatus, FormWrapper } from './contact.style'
import Author from '../../../assets/image/portfolio/avatar.png'

const ContactSchema = Yup.object().shape({
  nomEntreprise: Yup.string().required('Ce champs est obligatoire'),
  nom: Yup.string().required('Ce champs est obligatoire'),
  email: Yup.string()
    .email('Email invalide')
    .required('Ce champs est obligatoire'),
  description: Yup.string().required('Ce champs est obligatoire'),
})

const sendMail = (from, to, subject, domain, html, attachements) => {
  const data = new FormData()
  data.append('from', from)
  data.append('to', to)
  data.append('subject', subject)
  data.append('domain', domain)
  data.append('html', html)

  if (attachements && attachements.length > 0) {
    attachements.forEach((attachement, i) => {
      data.append(`file${i}`, attachement)
    })
  }

  return axios.post(
    'https://us-central1-innobelge-websites.cloudfunctions.net/emails',
    data
  )
}

const InputText = ({ name, placeholder, type }) => {
  return (
    <Field
      name={name}
      render={({ field, form: { touched, errors } }) => (
        <div>
          <Input
            inputType={type ? type : 'text'}
            placeholder={placeholder}
            iconPosition="right"
            isMaterial={false}
            className={cn(
              'email_input',
              touched[field.name] && errors[field.name] && 'error'
            )}
            id={name}
            name={name}
            {...field}
          />

          {touched[field.name] && errors[field.name] && (
            <div className="errorMessage">{errors[field.name]}</div>
          )}
        </div>
      )}
    />
  )
}

const ContactSection = ({
  sectionWrapper,
  secTitleWrapper,
  secTitle,
  secDescription,
  replyWrapper,
  headingForm,
  replyTime,
  buttonStyle,
  buttonWrapper,
}) => {
  return (
    <Box {...sectionWrapper} as="section" id="contact">
      <Container noGutter mobileGutter width="1200px">
        <Box {...secTitleWrapper}>
          <Heading {...secTitle} content="Contact" />
          <Text
            {...secDescription}
            content="Une question, un projet, une information ? N’hésitez pas, nous vous répondons dans un délai de 24h."
          />
        </Box>

        <Box>
          <FormWrapper>
            <Formik
              initialValues={{
                nomEntreprise: '',
                tvaEntreprise: '',
                siteEntreprise: '',
                nom: '',
                prenom: '',
                tel: '',
                email: '',
                description: '',
                attachments: [],
              }}
              onSubmit={(values, actions) => {
                swal({
                  title: "Message en cours d'envoi",
                  content: <ClipLoader />,
                  typr: 'info',
                  button: false,
                })

                const html = []
                Object.keys(values).forEach((key, index) => {
                  if (typeof values[key] === 'string') {
                    html.push(`<h3>${key}</h3><p>${values[key]}</p>`)
                  }
                })

                sendMail(
                  'noreply@expertgdpr.be',
                  //'contact@innosmile.be',
                  'marguerite.benjamin@gmail.be',
                  'Nouveau message expertgdpr.be',
                  'innobelge.be',
                  html.join(''),
                  values.attachments
                )
                  .then(() => {
                    swal(
                      'Message envoyé!',
                      'Nous vous répondons dans un délai de 24h',
                      'success'
                    )
                    actions.setSubmitting(false)
                    actions.resetForm()
                  })
                  .catch(error => {
                    swal('Good job!', error, 'error')
                  })
              }}
              validationSchema={ContactSchema}
              render={props => {
                return (
                  <form onSubmit={props.handleSubmit}>
                    <Row>
                      <Col md={12}>
                        <Heading {...headingForm} content="Votre entreprise" />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <InputText name="nomEntreprise" placeholder="Nom" />
                      </Col>
                      <Col md={6}>
                        <InputText
                          name="tvaEntreprise"
                          placeholder="Numéro TVA"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <InputText
                          name="siteEntreprise"
                          placeholder="Site Web"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <Heading
                          {...headingForm}
                          content="Personne de contact"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <InputText name="nom" placeholder="Nom" />
                      </Col>
                      <Col md={6}>
                        <InputText name="prenom" placeholder="Prénom" />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <InputText name="tel" placeholder="Tél / GSM" />
                      </Col>
                      <Col md={6}>
                        <InputText name="email" placeholder="Email" />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <Heading
                          {...headingForm}
                          content="Objet de votre demande"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <InputText
                          type="textarea"
                          name="description"
                          placeholder="Décrivez ici l'objet de votre demande"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <Dropzone
                          onDrop={acceptedFiles =>
                            props.setFieldValue('attachments', acceptedFiles)
                          }
                        >
                          {({ getRootProps, getInputProps }) => (
                            <React.Fragment>
                              <div
                                className="dropzoneContainer"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="dropzoneIcon">
                                  <Icon icon={cloud_upload} size={64} />
                                </div>
                                <p>
                                  Télécharger ici les photos et documentation
                                  relative à votre demande
                                </p>
                              </div>

                              <ul className="">
                                {props.values.attachments &&
                                  props.values.attachments.map(attachment => (
                                    <li
                                      key={
                                        attachment.name +
                                        attachment.lastModified
                                      }
                                      className=""
                                    >
                                      <Icon icon={clip} /> {attachment.name}
                                    </li>
                                  ))}
                              </ul>
                            </React.Fragment>
                          )}
                        </Dropzone>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <Box {...buttonWrapper}>
                          <ButtonWrapper>
                            <Button
                              title="Envoyer"
                              type="submit"
                              className="portfolio_button"
                              {...buttonStyle}
                            />
                          </ButtonWrapper>
                        </Box>
                      </Col>
                    </Row>
                  </form>
                )
              }}
            />
          </FormWrapper>
        </Box>
      </Container>
    </Box>
  )
}

ContactSection.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secTitle: PropTypes.object,
  secDescription: PropTypes.object,
  replyWrapper: PropTypes.object,
  replyTime: PropTypes.object,
  buttonStyle: PropTypes.object,
  buttonWrapper: PropTypes.object,
}

ContactSection.defaultProps = {
  sectionWrapper: {
    pt: ['70px', '80px', '100px', '110px', '140px'],
    pb: ['70px', '80px', '100px', '110px', '140px'],
  },
  secTitleWrapper: {
    mb: '30px',
  },
  secTitle: {
    fontSize: ['22px', '26px', '26px', '30px', '30px'],
    fontWeight: '600',
    color: '#302b4e',
    lineHeight: '1.34',
    mb: ['15px', '18px', '18px', '20px', '20px'],
    textAlign: 'center',
  },
  headingForm: {
    mt: '25px',
    as: 'h5',
  },
  secDescription: {
    fontSize: ['15px', '16px'],
    fontWeight: '400',
    color: '#43414e',
    lineHeight: '1.5',
    textAlign: 'center',
    width: '600px',
    maxWidth: '100%',
    ml: 'auto',
    mr: 'auto',
    mb: '0',
  },
  replyWrapper: {
    flexBox: true,
    alignItems: 'center',
    justifyContent: 'center',
  },
  replyTime: {
    fontSize: '16px',
    fontWeight: '600',
    color: '#302b4e',
    mb: 0,
  },
  buttonStyle: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#fff',
    pl: '23px',
    pr: '23px',
  },
  buttonWrapper: {
    flexBox: true,
    justifyContent: 'center',
    mt: '50px',
  },
}

export default ContactSection
