import React from 'react'

import { socialTwitter } from 'react-icons-kit/ionicons/socialTwitter'
import { socialFacebook } from 'react-icons-kit/ionicons/socialFacebook'
import { socialDribbbleOutline } from 'react-icons-kit/ionicons/socialDribbbleOutline'
import { socialGithub } from 'react-icons-kit/ionicons/socialGithub'
import { socialGoogleplusOutline } from 'react-icons-kit/ionicons/socialGoogleplusOutline'

import Awardee1 from '../../assets/image/portfolio/awardee-1.png'
import Awardee2 from '../../assets/image/portfolio/awardee-2.png'
import Awardee3 from '../../assets/image/portfolio/awardee-3.png'
import Awardee4 from '../../assets/image/portfolio/awardee-4.png'
import AwardImage1 from '../../assets/image/portfolio/award-1.png'
import AwardImage2 from '../../assets/image/portfolio/award-2.png'
import AwardImage3 from '../../assets/image/portfolio/award-3.png'
import AwardImage4 from '../../assets/image/portfolio/award-4.png'

import PortfolioImage1 from '../../assets/image/portfolio/portfolio-1.jpg'
import PortfolioImage2 from '../../assets/image/portfolio/portfolio-2.jpg'

import Step1 from '../../assets/image/portfolio/step-1.png'
import Step2 from '../../assets/image/portfolio/step-2.png'
import Step3 from '../../assets/image/portfolio/step-3.png'

import SkillIcon1 from '../../assets/image/portfolio/skill-1.svg'
import SkillIcon2 from '../../assets/image/portfolio/skill-2.svg'
import SkillIcon3 from '../../assets/image/portfolio/skill-3.svg'
import SkillIcon4 from '../../assets/image/portfolio/skill-4.svg'

import Client1 from '../../assets/image/portfolio/client-1.png'
import Client2 from '../../assets/image/portfolio/client-2.png'
import Client3 from '../../assets/image/portfolio/client-3.png'
import Client4 from '../../assets/image/portfolio/client-4.png'
import Client5 from '../../assets/image/portfolio/client-5.png'
import Client6 from '../../assets/image/portfolio/client-6.png'

import Reviewer1 from '../../assets/image/portfolio/client-avatar-1.jpg'
import Reviewer2 from '../../assets/image/portfolio/client-avatar-2.jpg'
import Reviewer3 from '../../assets/image/portfolio/client-avatar-3.jpg'

export const SOCIAL_PROFILES = [
  {
    icon: socialTwitter,
    url: '#',
  },
  {
    icon: socialFacebook,
    url: '#',
  },
  {
    icon: socialDribbbleOutline,
    url: '#',
  },
  {
    icon: socialGithub,
    url: '#',
  },
  {
    icon: socialGoogleplusOutline,
    url: '#',
  },
]

export const MENU_ITEMS = [
  {
    label: 'Accueil',
    path: '#accueil',
    offset: '0',
  },
  {
    label: 'Le RGPD',
    path: '#rgpd',
    offset: '0',
  },
  {
    label: 'Services',
    path: '#services',
    offset: '0',
  },
  {
    label: 'Contact',
    path: '#contact',
    offset: '0',
  },
]

export const AWARDS = [
  {
    awardLogo: AwardImage1,
    awardName: 'Free Software Advice',
    awardDetails: 'Top Rated App Development Companies USA',
    awardeeLogo: Awardee1,
    awardeeName: 'Awardee',
    date: 'The Jury 2018',
  },
  {
    awardLogo: AwardImage2,
    awardName: 'Free Software Advice',
    awardDetails: 'Top Rated App Development Companies USA',
    awardeeLogo: Awardee2,
    awardeeName: 'Awardee',
    date: 'The Jury 2018',
  },
  {
    awardLogo: AwardImage3,
    awardName: 'Free Software Advice',
    awardDetails: 'Top Rated App Development Companies USA',
    awardeeLogo: Awardee3,
    awardeeName: 'Awardee',
    date: 'The Jury 2018',
  },
  {
    awardLogo: AwardImage4,
    awardName: 'Free Software Advice',
    awardDetails: 'Top Rated App Development Companies USA',
    awardeeLogo: Awardee4,
    awardeeName: 'Awardee',
    date: 'The Jury 2018',
  },
]

export const PORTFOLIO_SHOWCASE = [
  {
    title: 'RGPD',
    portfolioItem: [
      {
        title: 'RGPD',
        description: (
          <>
            <p>
              Le RGPD est un règlement européen entré en application depuis le
              25/05/2018. Son objectif est d’établir un règlement unique
              garantissant la protection des données personnelles des citoyens
              européens.
            </p>
            <p>
              Cette réglementation s’applique dès lors à toutes les entreprises
              de l’Union européenne, petites ou grandes, et concerne toutes les
              activités (vente et marketing, ressources humaines, comptabilité,
              IT,...).
            </p>
            <p>
              Pour les entreprises situées en Belgique, la loi belge du 30
              juillet 2018 transposant le GDPR en droit belge précise les
              contours des obligations leurs incombant (publication au Moniteur
              Belge le 5 septembre 2018).
            </p>
            <p>
              Toute entreprise traitant des données personnelles est donc dans
              l'obligation de se conformer aux prescriptions légales et de
              pouvoir démontrer cette conformité. En cas de non-respect, des
              sanctions ont été prévues par le législateur belge.
            </p>
          </>
        ),
        image:
          'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/expert-gdpr/undraw_gdpr_3xfb.svg',
        link: '#',
        featuredIn: 'AWWWARDS',
        featuredLink: '#',
        view: '4.5K',
        love: '1.5K',
        feedback: '1.2K',
        buildWith: [
          {
            content: 'React JS',
          },
          {
            content: 'Next JS',
          },
          {
            content: 'Styled Component',
          },
        ],
      },
    ],
  },
  {
    title: 'La mise en conformité',
    portfolioItem: [
      {
        title: "C'est avant tout un processus de transformation",
        description: (
          <>
            <p>
              Au début de cette transformation, en tant que chef d'entreprise,
              vous souhaitez évaluer la maturité de votre entreprise en matière
              de protection des données personnelles. Avoir une vue précise de
              l'état des lieux, tel est le point de départ.
            </p>
            <ul>
              <li>
                Quelle est la base légale de la collecte des données
                personnelles que mon entreprise traite ?
              </li>
              <li>
                Mes clients sont-ils correctement informés de l'utilisation de
                leurs données personnelles ?
              </li>
              <li>Ai-je besoin de nommer un Data Protection Officer (DPO) ?</li>
              <li>
                Mon entreprise a-t-elle mis en place les modalités nécessaires
                pour permettre aux personnes, dont les données personnelles sont
                stockées, d'exercer leurs droits relatifs au RGPD. ?
              </li>
            </ul>
            <p>
              Toute une série de questions, certaines d'ordre juridique,
              d'autres d'ordre informatiques, vont rapidement vous permettre
              d'identifier les obligations qui incombent à votre entreprise, les
              actions à prendre, les process à dessiner, les outils nécessaires
              et les "gaps" à résoudre en priorité. Ca y est , vous avez votre
              roadmap RGPD.
            </p>
            <p>
              Afin de vous accompagner dans ce trajet, notre équipe est composée
              de program managers, d'experts informatiques et de consultants
              GDPR.
            </p>
          </>
        ),
        image:
          'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/expert-gdpr/undraw_personal_settings_kihd.svg',
        link: '#',
        featuredIn: 'AWWWARDS',
        featuredLink: '#',
        view: '4.5K',
        love: '1.5K',
        feedback: '1.2K',
        buildWith: [
          {
            content: 'React JS',
          },
          {
            content: 'Next JS',
          },
          {
            content: 'Styled Component',
          },
        ],
      },
    ],
  },
]

export const PROCESS_STEPS = [
  {
    image:
      'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/expert-gdpr/icone-audit.svg',
    title: 'Audit RGPD',
    description:
      'We work with you to understand user’s stories and validate your idea with real users using lean design sprints.',
  },
  {
    image:
      'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/expert-gdpr/icone-consultance.svg',
    title: 'Consultance RGPD',
    description:
      'Expanding on the insights gained, you’ll work closely with our design team to create an elegant design',
  },
  {
    image:
      'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/expert-gdpr/icone-dpo.svg',
    title: "Mise à disposition d'un Data Protection Officer (DPO)",
    description:
      'With our scrum-based agile methodology, you’ll receive iterative builds every two weeks, which gives you ',
  },
]

export const SERVICE_LIST = [
  {
    title: 'UI/UX Design',
    listItems: [
      {
        content: 'Design Sprints',
      },
      {
        content: 'User Research',
      },
      {
        content: 'Visual Design',
      },
      {
        content: 'Mobile App Design',
      },
      {
        content: 'Tracking & Learning',
      },
      {
        content: 'Building Traction',
      },
    ],
  },
  {
    title: 'Web Development',
    listItems: [
      {
        content: 'ReactJS',
      },
      {
        content: 'AngularJS',
      },
      {
        content: 'ASP.NET MVC',
      },
      {
        content: 'WordPress',
      },
      {
        content: 'NodeJS',
      },
      {
        content: 'GO',
      },
    ],
  },
  {
    title: 'Mobile App Development',
    listItems: [
      {
        content: 'iOS',
      },
      {
        content: 'Android',
      },
      {
        content: 'React Native',
      },
      {
        content: 'Ionic & Apache Cordova',
      },
      {
        content: 'NodeJS',
      },
      {
        content: '3D & VR',
      },
    ],
  },
]

export const SKILLS = [
  {
    title: 'Graphic Design',
    description:
      'Aristotle maintained the sharp distinction between science and the practical',
    icon: SkillIcon1,
    successRate: '90',
  },
  {
    title: 'UI/UX Design',
    description:
      'Aristotle maintained the sharp distinction between science and the practical',
    icon: SkillIcon2,
    successRate: '85',
  },
  {
    title: 'Web Application',
    description:
      'Aristotle maintained the sharp distinction between science and the practical',
    icon: SkillIcon3,
    successRate: '80',
  },
  {
    title: 'Mobile Application',
    description:
      'Aristotle maintained the sharp distinction between science and the practical',
    icon: SkillIcon4,
    successRate: '70',
  },
]

export const CLIENTS = [
  {
    image: Client1,
    title: 'Microsoft',
  },
  {
    image: Client2,
    title: 'Airbnb',
  },
  {
    image: Client3,
    title: 'Adidas',
  },
  {
    image: Client4,
    title: 'IBM',
  },
  {
    image: Client5,
    title: 'Amazon',
  },
  {
    image: Client6,
    title: 'Google',
  },
]

export const TESTIMONIAL = [
  {
    image: Reviewer1,
    review:
      'Another quality React-based product from RedQ Team. Manage to turn highly complex tech into simple components.',
    name: 'Thomas Cruz',
    designation: 'Founder & CEO',
    twitterProfile: 'https://twitter.com/redqinc',
    organization: '@Tonquin',
    organizationURL: 'https://redq.io/',
  },
  {
    image: Reviewer2,
    review:
      'Another quality React-based product from RedQ Team. Manage to turn highly complex tech into simple components.',
    name: 'Marhta Robson',
    designation: 'Co-Founder & CTO',
    twitterProfile: 'https://twitter.com/redqinc',
    organization: '@Tonquin',
    organizationURL: 'https://redq.io/',
  },
  {
    image: Reviewer3,
    review:
      'Another quality React-based product from RedQ Team. Manage to turn highly complex tech into simple components.',
    name: 'Dexter Patterson',
    designation: 'Co-Founder & COO',
    twitterProfile: 'https://twitter.com/redqinc',
    organization: '@Tonquin',
    organizationURL: 'https://redq.io/',
  },
]

export const FOOTER_MENU = [
  {
    label: 'Contact',
    path: '#',
  },
  {
    label: 'Privacy',
    path: '#',
  },
  {
    label: 'Cookie Policy',
    path: '#',
  },
]
