import styled from 'styled-components'

const BannerWrapper = styled.section`
  position: relative;
  background-image: linear-gradient(
    to right top,
    #283149,
    #2e3751,
    #343e59,
    #3a4461,
    #404b69
  );
  display: flex;
  align-items: center;
  padding-top: 80px;
  display: flex;
  align-items: center;
  text-align: center;
  @media (min-width: 991px) {
    min-height: 100vh;
  }

  .image_area {
    @media (max-width: 767px) {
      display: none;
    }
  }

  .skew-cc {
    width: 100%;
    height: 100px;
    position: absolute;
    left: 0px;
    bottom: 0px;
    background: linear-gradient(
        to right bottom,
        rgba(0, 0, 0, 0) 49%,
        #f4f9fb 50%
      ),
      linear-gradient(-50deg, #f4f9fb 16px, rgba(0, 0, 0, 0) 0);
  }
`

export default BannerWrapper
