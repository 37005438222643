import React from 'react'
import PropTypes from 'prop-types'
import Icon from 'react-icons-kit'
import Particles from 'react-particles-js'
import Img from 'react-image'
import Fade from 'react-reveal/Fade'

import Box from 'reusecore/src/elements/Box'
import Text from 'reusecore/src/elements/Text'
import Heading from 'reusecore/src/elements/Heading'
import Image from 'reusecore/src/elements/Image'
import Container from '../../../components/UI/Container'
import SocialProfile from '../SocialProfile'
import BannerWrapper from './banner.style'

import { SOCIAL_PROFILES } from '../../../data/Portfolio/data'
import { cornerDownRight } from 'react-icons-kit/feather/cornerDownRight'
import PersonImage from '../../../assets/image/portfolio/person.png'

const BannerSection = ({
  row,
  contentArea,
  imageArea,
  greetingStyle,
  nameStyle,
  designationStyle,
  aboutStyle,
  roleStyle,
  roleWrapper
}) => {
  console.log('nameStyle', nameStyle)
  return (
    <BannerWrapper id="accueil">
      <Particles
        style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}
        params={{
          particles: {
            number: {
              value: 50
            },
            size: {
              value: 3
            }
          },
          interactivity: {
            events: {
              onhover: {
                enable: true,
                mode: 'repulse'
              }
            }
          }
        }}
      />
      <Container noGutter mobileGutter width="800px">
        <Box {...row}>
          <Box {...contentArea}>
            <Img
              src="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/expert-gdpr/logo.main.svg"
              decode={false}
            />

            <Box
              {...roleWrapper}
              style={{
                textAlign: 'center'
              }}
            >
              <Heading
                content="Au carrefour du droit et de l'informatique"
                {...roleStyle}
              />
            </Box>
          </Box>
        </Box>
      </Container>
      <div className="skew-cc" />
    </BannerWrapper>
  )
}

BannerSection.propTypes = {
  row: PropTypes.object,
  contentArea: PropTypes.object,
  imageArea: PropTypes.object,
  greetingStyle: PropTypes.object,
  nameStyle: PropTypes.object,
  designationStyle: PropTypes.object,
  aboutStyle: PropTypes.object,
  roleStyle: PropTypes.object,
  roleWrapper: PropTypes.object
}

BannerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'stretch'
  },
  contentArea: {
    width: ['100%', '100%', '100%', '100%'],
    p: ['65px 0 80px 0', '65px 0 80px 0', '80px 0 60px 0', '0'],
    flexBox: true,
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  imageArea: {
    width: ['100%', '100%', '50%', '60%'],
    flexBox: true,
    alignItems: 'flex-end'
  },
  greetingStyle: {
    as: 'h3',
    color: '#fff',
    fontSize: ['18px', '18px', '18px', '20px', '30px'],
    fontWeight: '500',
    mb: '8px'
  },
  nameStyle: {
    as: 'h2',
    color: '#fff',
    fontSize: ['38px', '38px', '44px', '60px', '80px'],
    fontWeight: '800',
    mb: '6px'
  },
  designationStyle: {
    as: 'h3',
    color: '#fff',
    fontSize: ['18px', '18px', '18px', '20px', '30px'],
    fontWeight: '700',
    mb: ['30px', '30px', '25px', '30px', '30px']
  },
  roleWrapper: {
    flexBox: true,
    mb: '28px'
  },
  roleStyle: {
    as: 'h4',
    width: ['100%', '100%', '100%', '100%'],
    fontSize: ['18px', '18px', '18px', '18px', '20px'],
    fontWeight: '500',
    color: '#fff',
    mb: '0',
    ml: '10px'
  },
  aboutStyle: {
    fontSize: ['15px', '15px', '15px', '16px', '16px'],
    fontWeight: '400',
    color: '#fff',
    lineHeight: '1.5',
    mb: '50px'
  }
}

export default BannerSection
