import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Countdown from 'react-countdown-now'
import Box from 'reusecore/src/elements/Box'
import Fade from 'react-reveal/Fade'
import Card from 'reusecore/src/elements/Card'
import Image from 'reusecore/src/elements/Image'
import Text from 'reusecore/src/elements/Text'
import Heading from 'reusecore/src/elements/Heading'
import Button from 'reusecore/src/elements/Button'
import FeatureBlock from '../../../components/FeatureBlock'
import Container from '../../../components/UI/Container'
import { ControlWrapper } from './controlSection.style'
import {
  TrustedWrapper,
  FeatureSection,
} from '../TrustedProof/trustedProof.style'
import ControlImage from '../../../assets/image/crypto/control.jpg'

const Completionist = () => (
  <span className="readMore">You are good to go!</span>
)

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a complete state
    return <Completionist />
  } else {
    // Render a countdown
    return (
      <div className="countPortion">
        <div className="countSingle">
          {days} <span className="countText">Days</span>
        </div>
        <div className="countSingle">
          {hours} <span className="countText">Hours</span>
        </div>
        <div className="countSingle">
          {minutes} <span className="countText">Minutes</span>
        </div>
        <div className="countSingle">
          {seconds} <span className="countText">Seconds</span>
        </div>
      </div>
    )
  }
}
const ControlSection = ({
  row,
  col,
  title,
  description,
  btnStyle,
  sectionSubTitle,
  cardArea,
  readMoreTitle,
}) => {
  return (
    <ControlWrapper id="control">
      <Container>
        <Box className="row" {...row}>
          <Box className="colleft" {...col} style={{ flexDirection: 'column' }}>
            <Fade up>
              <Image
                src="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/expert-gdpr/undraw_gdpr_3xfb.svg"
                className="controlImage"
                alt="Control Section Image"
              />
            </Fade>
          </Box>

          <Box className="colright" {...col} {...cardArea}>
            <FeatureSection>
              <div className="featureWrapper">
                <Box className="contextPortion">
                  <Text
                    content="Le RGPD est un règlement européen entré en application depuis le
              25/05/2018. Son objectif est d’établir un règlement unique
              garantissant la protection des données personnelles des citoyens
              européens."
                  />
                </Box>
              </div>
              <div className="featureWrapper">
                <Box className="contextPortion">
                  <Text
                    content="Cette réglementation s’applique dès lors à toutes les entreprises
              de l’Union européenne, petites ou grandes, et concerne toutes les
              activités (vente et marketing, ressources humaines, comptabilité,
              IT,...)."
                  />
                </Box>
              </div>
              <div className="featureWrapper">
                <Box className="contextPortion">
                  <Text
                    content="Pour les entreprises situées en Belgique, la loi belge du 30
              juillet 2018 transposant le GDPR en droit belge précise les
              contours des obligations leurs incombant (publication au Moniteur
              Belge le 5 septembre 2018)."
                  />
                </Box>
              </div>
              <div className="featureWrapper">
                <Box className="contextPortion">
                  <Text
                    content="Toute entreprise traitant des données personnelles est donc dans
              l'obligation de se conformer aux prescriptions légales et de
              pouvoir démontrer cette conformité. En cas de non-respect, des
              sanctions ont été prévues par le législateur belge."
                  />
                </Box>
              </div>
            </FeatureSection>

            {/* 
            <Text {...sectionSubTitle} />
            <FeatureBlock
              title={<Heading {...title} />}
              description={
                <>
                  <Text
                    {...description}
                    content="Le RGPD est un règlement européen entré en application depuis le
              25/05/2018. Son objectif est d’établir un règlement unique
              garantissant la protection des données personnelles des citoyens
              européens."
                  />
                  <Text
                    {...description}
                    content="Cette réglementation s’applique dès lors à toutes les entreprises
              de l’Union européenne, petites ou grandes, et concerne toutes les
              activités (vente et marketing, ressources humaines, comptabilité,
              IT,...)."
                  />
                  <Text
                    {...description}
                    content="Pour les entreprises situées en Belgique, la loi belge du 30
              juillet 2018 transposant le GDPR en droit belge précise les
              contours des obligations leurs incombant (publication au Moniteur
              Belge le 5 septembre 2018)."
                  />
                </>
              }
            />
            */}
          </Box>
        </Box>
        <Box>
          <Text
            {...description}
            content="Toute entreprise traitant des données personnelles est donc dans l'obligation de se conformer aux prescriptions légales et de pouvoir démontrer cette conformité. En cas de non-respect, des sanctions ont été prévues par le législateur belge."
          />
        </Box>
      </Container>
    </ControlWrapper>
  )
}

// Transactions style props
ControlSection.propTypes = {
  sectionHeader: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  readMoreTitle: PropTypes.object,
  btnStyle: PropTypes.object,
}

// Transactions default style
ControlSection.defaultProps = {
  // Transactions section row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // Transactions section col default style
  col: {
    pr: '15px',
    pl: '15px',
    width: [1, 1 / 2, 1 / 2, 1 / 2, 1 / 2],
    flexBox: true,
    alignSelf: 'center',
  },

  // Transactions section title default style
  title: {
    content: 'Takeeeee control of your credit and identity.',
    fontSize: ['24px', '26px', '30px', '36px', '40px'],
    lineHeight: ['30px', '32px', '40px', '50px', '55px'],
    fontWeight: '700',
    color: '#32325d',
    letterSpacing: '-0.010em',
    mb: '20px',
    maxWidth: ['100%', '100%', '100%', '100%', '415px'],
    textAlign: ['left', 'left'],
  },
  // Transactions section description default style
  description: {
    content:
      'Crumbs makes crypto investing effortless and automated, so now you would not miss the right time to buy. From the customer wallet to the marchent wallet in a few minute.',
    fontSize: '16px',
    fontWeight: '400',
    color: '#525f7f',
    lineHeight: '28px',
    mb: ['30px', '30px', '35px', '35px', '45px'],
    textAlign: ['left', 'left'],
    maxWidth: ['100%', '100%', '100%', '100%', '430px'],
  },
  sectionSubTitle: {
    content: 'Effortless crypto for everyone.',
    as: 'span',
    textAlign: 'left',
    fontSize: ['16px', '16px', '18px', '20px', '20px'],
    fontFamily: 'Poppins',
    fontWeight: '600',
    lineHeight: '27px',
    color: '#525f7f',
    textAlign: ['left', 'left'],
  },
  // Button default style
  btnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
  },
  readMoreTitle: {
    content: 'Sale currently on hold. ',
    as: 'span',
    fontSize: ['18px', '18px', '20px', '20px', '20px'],
    lineHeight: ['25px', '27px', '27px', '27px', '27px'],
    fontWeight: '500',
    color: '#32325d',
    letterSpacing: '-0.010em',
    mb: '10px',
    textAlign: ['left', 'left'],
  },
}

export default ControlSection
