import styled from 'styled-components'

export const FormWrapper = styled.div`
  .dropzoneContainer {
    width: 100%;
    display: block;
    font-family: inherit;
    font-size: 16px;
    font-weight: 500;
    color: #797979;
    padding: 15px;
    margin: 5px 0px;
    text-align: center;
    border-color: #dadada;
    border-style: solid;
    border-width: 2px;
    border-color: #c7c7c7;
    border-radius: 10px;
    background-color: #fff;
  }
  .dropzoneIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #797979;
  }
  .error input,
  .error textarea {
    color: #e91c23 !important;
    border-color: #e91c23 !important;
  }
  .errorMessage {
    color: #e91c23;
    margin-bottom: 5px;
  }

  input {
    height: 100%;
    background: #fff;
    font-size: 16px;
    font-family: inherit;
    font-weight: 500;
    color: #343d48;
    padding: 15px;
    margin: 5px 0px;
    border-style: solid;
    border-width: 2px;
    border-color: #c7c7c7;
    border-radius: 10px;
    @media (max-width: 575px) {
    }
  }
  textarea {
    width: 100%;
    display: block;
    background: #fff;
    font-family: inherit;
    font-size: 16px;
    font-weight: 500;
    color: #343d48;
    padding: 15px;
    margin: 5px 0px;
    border-style: solid;
    border-width: 2px;
    border-color: #c7c7c7;
    border-radius: 10px;
    resize: vertical;
    @media (max-width: 575px) {
    }
  }
`
export const ActiveStatus = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: relative;
  flex-shrink: 0;
  margin-right: 20px;
  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #00ff24;
    border: 3px solid #fff;
    right: -2px;
    bottom: -2px;
  }

  img {
    width: 100%;
    height: auto;
    display: block;
  }
`
