import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Tabs, { TabPane } from 'rc-tabs'
import Fade from 'react-reveal/Fade'
import { Container as GridContainer, Row, Col } from 'react-grid-system'
import TabContent from 'rc-tabs/lib/TabContent'
import Img from 'react-image'
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar'
import Box from 'reusecore/src/elements/Box'
import Text from 'reusecore/src/elements/Text'
import Heading from 'reusecore/src/elements/Heading'
import Image from 'reusecore/src/elements/Image'
import Container from '../../../components/UI/Container'
import GlideCarousel from '../../../components/GlideCarousel'
import GlideSlide from '../../../components/GlideCarousel/glideSlide'

import {
  PortfolioShowcaseWrapper,
  PortfolioShowcaseItem,
  PortfolioLink,
  BuiltWith,
  PortfolioMeta,
  MetaItem,
} from './portfolioShowcase.style'
import {
  PrevButton,
  NextButton,
} from '../../../containers/Portfolio/portfolio.style'
import { PORTFOLIO_SHOWCASE } from '../../../data/Portfolio/data'

const PortfolioShowcase = ({
  sectionWrapper,
  secTitleWrapper,
  secSecondTitleWrapper,
  secTitle,
  secDescription,
  portfolioImage,
  portfolioDetails,
  titleStyle,
  detailsStyle,
}) => {
  //Carousel Options
  const carouselOptions = {
    type: 'carousel',
    perView: 1,
    gap: 0,
    animationDuration: 900,
  }

  return (
    <Box {...sectionWrapper} as="section" id="rgpd">
      <PortfolioShowcaseWrapper>
        <Container noGutter mobileGutter width="1200px">
          <Box {...secTitleWrapper}>
            <Row align="center">
              <Col md={6}>
                <PortfolioLink>
                  <a>Le RGPD</a>
                </PortfolioLink>
                <Heading
                  {...secTitle}
                  content="Règlement général sur la protection des données"
                />
                <Fade up cascade>
                  <div>
                    <Text content="Le RGPD est un règlement européen entré en application depuis le 25/05/2018. Son objectif est d’établir un règlement unique garantissant la protection des données personnelles des citoyens européens." />
                    <Text content="Cette réglementation s’applique dés lors à toutes les entreprises de l’Union européenne, petites ou grandes, et concerne toutes les activités (vente et marketing, ressources humaines, comptabilité, IT,...)." />
                    <Text content="Pour les entreprises situées en Belgique, la loi belge du 30 juillet 2018 transposant le GDPR en droit belge précise les contours des obligations leurs incombant (publication au Moniteur Belge le 5 septembre 2018)." />
                  </div>
                </Fade>
              </Col>
              <Col md={6}>
                <Fade up>
                  <div className="img-wrapper">
                    <Img src="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/expert-gdpr/undraw_gdpr_3xfb%20(1).svg" />
                  </div>
                </Fade>
              </Col>
            </Row>
          </Box>
          <Box {...secSecondTitleWrapper}>
            <Row align="center">
              <Col md={6}>
                <Fade up>
                  <div className="img-wrapper">
                    <Img src="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/expert-gdpr/undraw_accept_terms_4in8.svg" />
                  </div>
                </Fade>
              </Col>
              <Col md={6}>
                <PortfolioLink>
                  <a>La mise en conformité RGPD</a>
                </PortfolioLink>
                <Heading
                  {...secTitle}
                  content="C'est avant tout un processus de transformation"
                />
                <Fade up cascade>
                  <div>
                    <Text content="Au début de cette transformation, en tant que chef d'entreprise, vous souhaitez évaluer la maturité de votre entreprise en matière de protection des données personnelles.  Avoir une vue précise de l'état des lieux, tel est le point de départ." />
                    <ul>
                      <li>
                        Quelle est la base légale de la collecte des données
                        personnelles que mon entreprise traite ?
                      </li>
                      <li>
                        Mes clients sont-ils correctement informés de
                        l'utilisation de leurs données personnelles ?
                      </li>
                      <li>
                        Ai-je besoin de nommer un Data Protection Officer (DPO)
                        ?
                      </li>
                      <li>
                        Mon entreprise a-t-elle mis en place les modalités
                        nécessaires pour permettre aux personnes, dont les
                        données personnelles sont stockées, d'exercer leurs
                        droits relatifs au RGPD. ?
                      </li>
                    </ul>
                    <Text content="Toute une série de questions, certaines d'ordre juridique, d'autres d'ordre informatiques, vont rapidement vous permettre d'identifier les obligations qui incombent à votre entreprise, les actions à prendre, les process à dessiner, les outils nécessaires et les gaps à résoudre en priorité. Ca y est , vous avez votre roadmap RGPD." />
                    <Text content="Afin de vous accompagner dans ce trajet, notre équipe est composée de program managers,  d'experts informatiques et de consultants GDPR." />
                  </div>
                </Fade>
              </Col>
            </Row>
          </Box>
        </Container>
      </PortfolioShowcaseWrapper>
    </Box>
  )
}

PortfolioShowcase.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secTitle: PropTypes.object,
  secDescription: PropTypes.object,
  portfolioImage: PropTypes.object,
  portfolioDetails: PropTypes.object,
  titleStyle: PropTypes.object,
  detailsStyle: PropTypes.object,
}

PortfolioShowcase.defaultProps = {
  sectionWrapper: {
    pt: ['60px', '80px', '100px', '110px', '150px'],
    pb: ['60px', '80px', '100px', '110px', '50px'],
  },
  secTitleWrapper: {
    width: ['100%', '100%', '100%', '100%', '100%'],
    mb: ['50px', '200px'],
  },
  secSecondTitleWrapper: {
    width: ['100%', '100%', '100%', '100%', '100%'],
    mb: ['50px', '0px'],
  },
  secTitle: {
    fontSize: ['22px', '26px', '26px', '30px', '30px'],
    fontWeight: '600',
    color: '#404b69',
    lineHeight: '1.34',
    mb: ['25px', '28px', '28px', '40px', '40px'],
  },
  secDescription: {
    fontSize: ['15px', '16px'],
    fontWeight: '400',
    color: '#43414e',
    lineHeight: '1.5',
    mb: '0',
  },
  portfolioImage: {
    width: [1, 1, 1 / 2],
  },
  portfolioDetails: {
    width: [1, 1, 1 / 2],
    p: ['30px 0 0 0', '40px 0 0 0', '0 0 0 30px', '0 50px', '0 50px'],
  },
  titleStyle: {
    fontSize: ['22px', '22px', '26px', '40px', '40px'],
    fontWeight: '600',
    color: '#302b4e',
    mb: '17px',
  },
  detailsStyle: {
    fontSize: ['15px', '15px', '15px', '16px', '16px'],
    color: '#43414e',
    lineHeight: '1.5',
    mb: '0',
  },
}

export default PortfolioShowcase
